import * as React from "react"
import { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import { historyBlockHide } from "../../../../redux/reducers/contact/historyNotesDisplay"
import Moment from "react-moment"
import { Link } from "gatsby"
import { CUSTOM_ADDRESS, DATE_FORMAT } from "../../contactHelper"
import moment from "moment"
import {
  CHAT_TO_ICON,
  CHAT_TO_TYPE,
  chatToLabel,
  EMAIL_ICON,
  EMAIL_TYPE,
  emailLabel,
  HISTORY_ICON,
  HISTORY_TYPE,
  historyOptionLabel,
  MAP_ICON,
  MAP_TYPE,
  mapLabel,
  PHONE_ICON,
  PHONE_TYPE,
  phoneLabel,
  POSTAL_MAIL_ICON,
  POSTAL_MAIL_TYPE,
  postalMailLabel,
  PRIVATE_NOTE_ICON,
  PRIVATE_NOTE_TYPE,
  privateNoteLabel,
  SEQUENCE_ICON,
  SEQUENCE_TYPE,
  sequenceLabelForHistory,
  SMS_ICON,
  SMS_TYPE,
  smsLabel,
  VIDEO_CALL_ICON,
  VIDEO_CALL_TYPE,
  VIDEO_RECORDING_ICON,
  VIDEO_RECORDING_TYPE,
  videoCallLabel,
  videoRecordingLabel,
} from "./historyNoteHelper"
import SendEMail from "./sendEmail"
import { getFullName, getProfileImage } from "../../../../services/settingsHelper"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const History = ({
  contact,
  contactName,
  profilePic,
  emails,
  phones,
  addresses,
  customFields,
  historyNotes,
  setHistoryNotes,
  setShowHistory,
  historyType,
  setHistoryType,
  historyLabel,
  setHistoryLabel,
  historyIcon,
  setHistoryIcon,
  historyValue,
  setHistoryValue,
  sequenceData,
  contactAllEvent,
  selectedContactId,
}) => {
  // console.log(historyLabel)
  const headerContainerHeight = 5
  const [chatBlockHeight, setChatBlockHeight] = useState(window.innerHeight),
    [chatBlockTop, setChatBlockTop] = useState(headerContainerHeight),
    [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
    [updateHistoryBlock, setUpdateHistoryBlock] = useState(false),
    [notesTextArea, setNotesTextArea] = useState(""),
    [notesTextAreaError, setNotesTextAreaError] = useState(""),
    [addNoteArea, setAddNoteArea] = useState(true),
    [generateReport, setGenerateReport] = useState(false),
    [callNow, setCallNow] = useState(false),
    [userEMails, setUserEMails] = useState([]),
    [defaultSequence, setDefaultSequence] = useState(""),
    [selectedEventId, setSelectedEventId] = useState(""),
    [selectedEventTitle, setSelectedEventTitle] = useState("== Event =="),
    [sequenceType, setSequenceType] = useState(""),
    [contactSequenceData, setContactSequenceData] = useState([])

  let profileImage = getProfileImage()
  if (!profileImage) {
    profileImage = "StaticImages/avatar.gif"
  }

  const addNotes = () => {
    if (notesTextArea && contact.id) {
      setNotesTextAreaError("")

      return new Promise(async () => {
        await AxiosInstance.post(`/history-note/add`, {
          contactId: contact.id,
          content: notesTextArea,
          mode: "public",
          type: "note",
          status: true,
        })
          .then(function (response) {
            if (response.status === 201) {
              setNotesTextArea("")
              setUpdateHistoryBlock(true)
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setNotesTextAreaError(error.response.data.message)
            } else {
              setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  const addSequence = (sequenceId, selectSequenceType) => {
    if (selectSequenceType !== "BeforeAfter" || (selectSequenceType === "BeforeAfter" && selectedEventId)) {
      return new Promise(async () => {
        await AxiosInstance.post(`drive/sequence/assign-sequence-contact/${sequenceId}/${contact.id}`)
          .then(function (response) {
            if (response.status === 201 || response.status === 200) {
              getSequenceContactList()
              setDefaultSequence("")
              setSelectedEventId("")
              setSelectedEventTitle("== Event ==")

              console.log("success")
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setDefaultSequence("")
              alert(error.response.data.message)
            }
            console.log(error)
          })
      })
    }
  }

  const unassignContactSequence = (id, sequenceName) => {
    if (!window.confirm(`Delete ${sequenceName}?`)) {
      return false
    }

    return new Promise(async () => {
      AxiosInstance.delete(`/drive/sequence/un-assign-sequence-contact/${id}`).then(response => {
        getSequenceContactList()
      })
    })
  }

  const getSequenceContactList = () => {
    return new Promise(async () => {
      AxiosInstance.get(`/drive/sequence/contact/list?contact_id=${selectedContactId}`).then(response => {
        setContactSequenceData(response.data.items)
      })
    })
  }

  const getHistoryNotes = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/contact/history-note/${contact.id}`)
        .then(function (response) {
          setHistoryNotes(response.data)
          return true
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setNotesTextAreaError(error.response.data.message)
          } else {
            setNotesTextAreaError(error.response.data.detail)
          }
        })
    })
  }

  const getUserEmails = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/settings/user/emails`)
        .then(function (response) {
          if (response.data.emails.length > 0) {
            setUserEMails(response.data.emails)
          } else {
            setUserEMails(["noreply@SwiftCRM.com", "Connect Email Sender"])
          }
          return true
        })
        .catch(function (error) {
          setUserEMails([])
        })
    })
  }

  const updateHistoryBlockClose = () => {}

  const showAddNote = () => {
    setAddNoteArea(true)
    setGenerateReport(false)
  }

  const showGenerateReport = () => {
    setAddNoteArea(false)
    setGenerateReport(true)
  }

  const updateHistoryBlockHeight = () => {
    setChatBlockHeight(window.innerHeight - headerContainerHeight)
    setChatBlockTop(headerContainerHeight)

    if (historyType === "history") {
      setHistoryBlockHeight(
        window.innerHeight - headerContainerHeight - document.querySelector(".ChatDockHeader").clientHeight
      )
    } else {
      setHistoryBlockHeight(
        window.innerHeight -
          headerContainerHeight -
          document.querySelector(".ChatDockHeader").clientHeight -
          document.querySelector(".ChatDockFooterContainer").clientHeight
      )
    }
  }

  useEffect(() => {
    if (updateHistoryBlock) {
      setUpdateHistoryBlock(false)
      if (contact.id) {
        getHistoryNotes()
      }
    }

    let updateHistoryBlockHeightInterval = setInterval(() => {
      updateHistoryBlockHeight()
      clearInterval(updateHistoryBlockHeightInterval)
    }, 500)
  })

  useEffect(() => {
    getUserEmails()
  }, [])

  return (
    <div className="col-lg-6 col-md-12">
      <div className="SCChatDockNonSocialAssetOnly d-none d-lg-block">
        <div className="ChatDockHeader">
          <div className="user_info">
            <img src={profilePic} className="img-fluid" alt={contactName} />
            {/*<span className="user_status user_online" data-bs-toggle="tooltip" title="Online" />*/}
            {contactName}
          </div>
          <div className="ChatDockInputOptContainer">
            <div className="dropdown ChatDockInputOpt">
              <Dropdown
                id="dropdown-basic-button"
                title={historyLabel}
                className={"ChatDockInputOpt"}
                variant={"secondary"}
                // prefixIcon=Icon{historyIcon}
                onSelect={(e, event) => {
                  setHistoryValue(null)
                  if (event.target.attributes["value"].nodeValue === EMAIL_TYPE) {
                    setHistoryValue(event.target.attributes["data-value"].nodeValue)
                  }

                  if (event.target.attributes["value"].nodeValue === SEQUENCE_TYPE) {
                    getSequenceContactList()
                  }

                  setHistoryType(event.target.attributes["value"].nodeValue)
                  setHistoryIcon(event.target.attributes["data-icon"].nodeValue)
                  setHistoryLabel(() => {
                    return <>{event.target.attributes["data-label"].nodeValue}</>
                  })
                  if (event.target.attributes["data-value"]) {
                    let dataValue = event.target.attributes["data-value"].nodeValue
                    if (dataValue) {
                      setHistoryValue(dataValue)
                    }
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <i className={historyIcon} />
                  &nbsp;{historyLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
                  <Dropdown.Item
                    value={HISTORY_TYPE}
                    data-icon={HISTORY_ICON}
                    data-label={historyOptionLabel()}
                  >
                    <i className={HISTORY_ICON} /> {historyOptionLabel()}
                  </Dropdown.Item>
                  <Dropdown.Item
                    value={PRIVATE_NOTE_TYPE}
                    data-icon={PRIVATE_NOTE_ICON}
                    data-label={privateNoteLabel()}
                  >
                    <i className={PRIVATE_NOTE_ICON} /> {privateNoteLabel()}
                  </Dropdown.Item>

                  <Dropdown.Item
                    value={SEQUENCE_TYPE}
                    data-icon={SEQUENCE_ICON}
                    data-label={sequenceLabelForHistory()}
                  >
                    <i className={SEQUENCE_ICON} /> {sequenceLabelForHistory()}
                  </Dropdown.Item>

                  <Dropdown.Item
                    value={CHAT_TO_TYPE}
                    data-icon={CHAT_TO_ICON}
                    data-label={chatToLabel(contactName)}
                  >
                    <i className={CHAT_TO_ICON} /> {chatToLabel(contactName)}
                  </Dropdown.Item>

                  {emails.length > 0 &&
                    emails.map((email, index) => {
                      return (
                        <React.Fragment key={index}>
                          {email.email && (
                            <Dropdown.Item
                              value={EMAIL_TYPE}
                              data-value={email.email}
                              data-icon={EMAIL_ICON}
                              data-label={emailLabel(email.email)}
                            >
                              <i className={EMAIL_ICON} /> {emailLabel(email.email)}
                            </Dropdown.Item>
                          )}
                        </React.Fragment>
                      )
                    })}

                  {/*{phones.length > 0 &&
                    phones.map((phone, index) => {
                      return (
                        <React.Fragment key={index}>
                          {phone.phone && (
                            <Dropdown.Item
                              value={SMS_TYPE}
                              data-value={phone.phone}
                              data-icon={SMS_ICON}
                              data-label={smsLabel(phone.phone)}
                            >
                              <i className={SMS_ICON} /> {smsLabel(phone.phone)}
                            </Dropdown.Item>
                          )}
                        </React.Fragment>
                      )
                    })}

                  {phones.length > 0 &&
                    phones.map((phone, index) => {
                      return (
                        <React.Fragment key={index}>
                          {phone.phone && (
                            <Dropdown.Item
                              value={PHONE_TYPE}
                              data-value={phone.phone}
                              data-icon={PHONE_ICON}
                              data-label={phoneLabel(phone.phone)}
                            >
                              <i className={PHONE_ICON} /> {phoneLabel(phone.phone)}
                            </Dropdown.Item>
                          )}
                        </React.Fragment>
                      )
                    })}*/}

                  {/*<Dropdown.Item
                  value={VIDEO_RECORDING_TYPE}
                  data-icon={VIDEO_RECORDING_ICON}
                  data-label={videoRecordingLabel()}
                >
                  <i className={VIDEO_RECORDING_ICON} /> {videoRecordingLabel()}
                </Dropdown.Item>

                <Dropdown.Item
                  value={VIDEO_CALL_TYPE}
                  data-icon={VIDEO_CALL_ICON}
                  data-label={videoCallLabel()}
                >
                  <i className={VIDEO_CALL_ICON} /> {videoCallLabel()}
                </Dropdown.Item>*/}

                  {addresses.length > 0 &&
                    addresses.map((address, index) => {
                      return (
                        <React.Fragment key={index}>
                          {address.address && (
                            <Dropdown.Item
                              value={MAP_TYPE}
                              data-value={address.address}
                              data-icon={MAP_ICON}
                              data-label={`Directions to ${address.address}`}
                            >
                              <i className={MAP_ICON} /> Directions to {address.address}
                            </Dropdown.Item>
                          )}
                        </React.Fragment>
                      )
                    })}

                  {customFields.length > 0 &&
                    customFields.map((customField, index) => {
                      if (customField.type === CUSTOM_ADDRESS) {
                        return (
                          <React.Fragment key={index}>
                            {customField.data && (
                              <Dropdown.Item
                                value={MAP_TYPE}
                                data-value={customField.data}
                                data-icon={MAP_ICON}
                                data-label={mapLabel(customField.data)}
                              >
                                <i className={MAP_ICON} /> {mapLabel(customField.data)}
                              </Dropdown.Item>
                            )}
                          </React.Fragment>
                        )
                      } else {
                        return <React.Fragment key={index}></React.Fragment>
                      }
                    })}

                  {addresses.length > 0 &&
                    addresses.map((address, index) => {
                      return (
                        <React.Fragment key={index}>
                          {address.address && (
                            <Dropdown.Item
                              value={POSTAL_MAIL_TYPE}
                              data-value={address.address}
                              data-icon={POSTAL_MAIL_ICON}
                              data-label={`Mail to ${address.address}`}
                            >
                              <i className={POSTAL_MAIL_ICON} /> {postalMailLabel(address.address)}
                            </Dropdown.Item>
                          )}
                        </React.Fragment>
                      )
                    })}

                  {customFields.length > 0 &&
                    customFields.map((customField, index) => {
                      if (customField.type === CUSTOM_ADDRESS) {
                        return (
                          <React.Fragment key={index}>
                            {customField.data && (
                              <Dropdown.Item
                                value={POSTAL_MAIL_TYPE}
                                data-value={customField.data}
                                data-icon={POSTAL_MAIL_ICON}
                                data-label={postalMailLabel(customField.data)}
                              >
                                <i className={POSTAL_MAIL_ICON} /> {postalMailLabel(customField.data)}
                              </Dropdown.Item>
                            )}
                          </React.Fragment>
                        )
                      } else {
                        return <React.Fragment key={index}></React.Fragment>
                      }
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <a
            className="btnCloseChatDock"
            data-toggle="tooltip"
            data-placement="left"
            data-animation="false"
            title=""
            data-bs-original-title="Close"
            aria-label="Close"
            onClick={() => setShowHistory(false)}
          >
            <i className="bi bi-x-lg" />
          </a>
        </div>
        <div
          className="ChatDockHistoryContainer mCustomScrollbar _mCS_1"
          style={{ height: historyBlockHeight }}
        >
          <div id="mCSB_1" className="mCS-dark mCSB_vertical mCSB_inside" tabIndex="0">
            <div
              id="mCSB_1_container"
              className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
              style={{
                position: "relative",
                top: "0",
                left: "0",
                overflow: "auto",
                height: historyBlockHeight,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              dir="ltr"
            >
              <ul className="chat">
                {historyNotes !== null &&
                  historyNotes.length > 0 &&
                  historyNotes.map((historyData, historyIndex) => {
                    let chatClass = "other"
                    let imagePath = profilePic
                    let userName = contactName
                    if (historyData.messageSent) {
                      chatClass = "self"
                      imagePath = `${process.env.GATSBY_WRANGLER_URL}${profileImage}`
                      userName = getFullName()
                    }
                    return (
                      <React.Fragment key={historyIndex}>
                        {(historyData.content || historyData.title) && (
                          <li className={chatClass}>
                            <div className="chat-msg">
                              <div className="avatar">
                                <a
                                  href="#"
                                  className="quickcontact_userSelect d-block"
                                  data-bs-toggle="tooltip"
                                  data-placement="bottom"
                                  data-animation="false"
                                  title={userName}
                                >
                                  <img src={imagePath} alt="" />
                                </a>
                              </div>
                              <div className="msg">
                                {historyData.title && (
                                  <p className="subjectLine">
                                    <strong>{historyData.title}</strong>
                                  </p>
                                )}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: historyData.content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                                  }}
                                ></p>
                              </div>
                            </div>

                            <span className="msg-by-time">
                              <Moment format="MMM D, YYYY">{historyData.updatedAt}</Moment>&nbsp;
                              <span className="chat-msg-time-detail">
                                (<Moment format="dddd">{historyData.updatedAt}</Moment>) -&nbsp;
                                {historyData.type === EMAIL_TYPE && (
                                  <>
                                    <i className="bi bi-envelope-fill " />
                                    &nbsp; Email sent from&nbsp;
                                    {historyData.privateMetaJson.emailFrom}&nbsp; to&nbsp;
                                    {historyData.privateMetaJson.emailTo}&nbsp;
                                  </>
                                )}
                                {historyData.type === CHAT_TO_TYPE ||
                                  (historyData.type === HISTORY_TYPE && (
                                    <>
                                      <i className="bi bi-chat-dots-fill " /> Chat by&nbsp;
                                      <Link to={`/contact/detail/${historyData.contactId}`}>
                                        @{contactName}
                                      </Link>
                                      &nbsp;
                                    </>
                                  ))}
                                at &nbsp;
                                <Moment format="h:mm a">{historyData.updatedAt}</Moment>
                              </span>
                            </span>
                          </li>
                        )}
                      </React.Fragment>
                    )
                  })}

                {/*<li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title="SwiftCloud"
                      >
                        <img src="images/swift_cloud_flag.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>
                        <strong>Welcome to SwiftCloud!</strong>
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry's standard dummy text ever since the 1500s
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 10, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Friday) - <i className="bi bi-chat-dots-fill " /> Chat by{" "}
                      <a href="contact_detail.html">@SwiftCloud</a> at <time>5:48 pm</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p className="mb-2">
                        <a href="#" className="dashedLinked">
                          Non-Disclosure Agreement
                        </a>{" "}
                        eSigned
                      </p>
                      <p>
                        <a href="LINKTODATABASE" className="chatDockFileIcon">
                          <span className="calendarIcon">
                            <i className="bi bi-calendar3 " />
                          </span>{" "}
                          Calendar
                        </a>
                        <a href="LINKTODATABASE" className="chatDockFileIcon">
                          <span className="personIcon">
                            <i className="bi bi-person-fill " />
                          </span>{" "}
                          Contact
                        </a>
                        <a href="LINKTOWORKROOM" className="chatDockFileIcon">
                          <span className="workroomIcon">
                            <i className="bi bi-check2-square " />
                          </span>{" "}
                          Workroom
                        </a>
                        <a href="LINKTOPDF" className="chatDockFileIcon">
                          <span className="pdfIconBg">
                            <i className="bi bi-file-earmark-pdf-fill " />
                          </span>{" "}
                          PDF{" "}
                        </a>
                        <a href="LINKTODATABASE" className="chatDockFileIcon">
                          <span className="dataIcon">
                            <i className="bi bi-table " />
                          </span>{" "}
                          Data
                        </a>
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 10, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Friday) - <a href="contact_detail.html">{contactName}</a> signed your{" "}
                      <i className="bi bi-link-45deg " /> Non-Disclosure Agreement on <time>5:55 am</time>
                    </span>
                  </span>
                </li>

                <li className="self">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="quickcontact_userSelect d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title="Roger V."
                      >
                        <img src="images/roger.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Do you like fish?</p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 11, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Saturday) - <i className="bi bi-chat-dots-fill " /> Chat by{" "}
                      <a href="contact_detail.html">you</a> at <time>10:00 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Yes</p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 11, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Saturday) - <i className="bi bi-chat-dots-fill " /> Chat by{" "}
                      <a href="contact_detail.html">@AmitabhB</a> at <time>10:10 am</time>
                    </span>
                  </span>
                </li>

                <li className="self">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title="Roger V."
                      >
                        <img src="images/roger.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Hi Amit! How can I help? This SMS was piped through Twilio API.</p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 12, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Sunday) - <i className="bi bi-phone " /> SMS by <a href="contact_detail.html">you</a>{" "}
                      at <time>10:00 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>
                        The voicemail transcribed shows here, which is done via API or Google Voice or Twilio.
                      </p>
                      <p>
                        <audio controls="">
                          <source src="http://www.w3schools.com/html/horse.ogg" type="audio/ogg" />
                          <source src="http://www.w3schools.com/html/horse.mp3" type="audio/mpeg" />
                          <a href="http://www.w3schools.com/html/horse.mp3">horse</a>
                        </audio>
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    June 12, 2022{" "}
                    <span className="chat-msg-time-detail">
                      (Sunday) - <i className="bi bi-telephone-fill " /> Voicemail from{" "}
                      <a href="contact_detail.html">987-654-3210</a> at <time>11:30 am</time>
                    </span>
                  </span>
                </li>

                <li className="self">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="quickcontact_userSelect d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title="Roger V."
                      >
                        <img src="images/roger.jpg" />
                      </a>
                      <span
                        className="chatDockAimoIcon"
                        data-bs-toggle="tooltip"
                        title="Aimo as You - 98% Confidence Score - Sent Automatically"
                      >
                        <i className="bi bi-robot " />
                      </span>
                    </div>
                    <div className="msg">
                      <p className="subjectLine">Re: New Studio $$</p>
                      <p>
                        Still need that $12M we talked about to build that new studio? I’ve got an investor if
                        so.
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Jan 24, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Tuesday) - <i className="bi bi-envelope-fill " /> Email sent from{" "}
                      <a href="contact_detail.html">Roger@Yo.com</a> to{" "}
                      <a href="contact_detail.html">amitabh@domain.com</a> at <time>12:00 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <div className="">
                        <video className="w-100" controls loop muted>
                          <source src="images/video.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Jan 25, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-camera-video-fill " />{" "}
                      <a href="contact_detail.html">{contactName}</a> Video Message on <time>4:00 pm</time>
                    </span>
                  </span>
                </li>

                <li className="self">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="quickcontact_userSelect d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title="Roger V."
                      >
                        <img src="images/roger.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <div className="chatDockFilePreview chatDockFilePreviewPDF">
                        <div className="chatDockFileIcon">
                          <a href="LINKTOFILE">
                            <i className="bi bi-file-earmark-pdf-fill " />
                          </a>
                        </div>
                        <div className="chatDockFileName">
                          <a href="LINKTOFILE">filename.pdf</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Jan 31, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Tuesday) - <i className="bi bi-chat-dots-fill " /> Sent by{" "}
                      <a href="contact_detail.html">@Roger</a> at <time>3:15 pm</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <div className="chatDockFilePreview chatDockFilePreviewSpreadsheet">
                        <div className="chatDockFileIcon">
                          <a href="LINKTOFILE">
                            <i className="bi bi-table " />
                          </a>
                        </div>
                        <div className="chatDockFileName">
                          <a href="LINKTOFILE">spreadsheet.csv</a> <i className="bi bi-check2 color-green " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 1, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-table " />{" "}
                      <a href="contact_detail.html">{contactName}</a> sent a spreadsheet at{" "}
                      <time>11:00 am</time>
                    </span>
                  </span>
                </li>

                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>
                        {contactName} scheduled a Dental Cleaning online for{" "}
                        <a href="LINK_TO_CALENDAR" className="dashedLinked">
                          Friday, March 3, 2023 @ 4:00 pm
                        </a>
                        .
                      </p>

                      <div className="chatDockEventBox">
                        <i className="bi bi-three-dots " /> Sequence Started:{" "}
                        <a href="LINKS_TO_SEQUENCE" className="dashedLinked">
                          Appointment Confirmation
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 1, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-calendar3 " /> Added to Calendar by{" "}
                      <a href="contact_detail.html">{contactName}</a> at <time>11:15 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>
                        Appointment Scheduled with {contactName} for Moving Services on{" "}
                        <a href="LINK_TO_CALENDAR" className="dashedLinked">
                          Monday, June 5, 2023 @ 9:00 am
                        </a>
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 1, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-calendar3 " /> Added to Calendar by{" "}
                      <a href="contact_detail.html">{contactName}</a> at <time>11:15 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>{contactName} Dental Cleaning</p>
                      <ul className="user-card-list">
                        <li>
                          <img src="images/bachchan-amitabh-image.jpg" alt="" className="user_avatar" />
                          Amitabh was there <i className="bi bi-check2 color-green " />
                        </li>
                      </ul>
                      <div className="chatDockEventBox">
                        <i className="bi bi-three-dots " /> Sequence Started:{" "}
                        <a href="LINKS_TO_SEQUENCE" className="dashedLinked">
                          How'd we do?
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 3, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Friday) - <i className="bi bi-three-dots " />{" "}
                      <a href="contact_detail.html">{contactName}</a> at <time>4:00 pm</time>
                    </span>
                  </span>
                </li>

                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>Dental Cleaning & Checkup</p>
                      <ul className="user-card-list">
                        <li>
                          <img src="images/bachchan-amitabh-image.jpg" alt="" className="user_avatar" />
                          Amitabh was not there <i className="bi bi-x-lg color-red " />
                        </li>
                      </ul>
                      <div className="chatDockEventBox">
                        <i className="bi bi-three-dots " /> Sequence Started:{" "}
                        <a href="LINKS_TO_SEQUENCE" className="dashedLinked">
                          Appointment Missed
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 1, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-three-dots " />{" "}
                      <a href="contact_detail.html">{contactName}</a> at <time>11:15 am</time>
                    </span>
                  </span>
                </li>
                <li className="other">
                  <div className="chat-msg">
                    <div className="avatar">
                      <a
                        href="javascript:;"
                        className="d-block"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title={contactName}
                      >
                        <img src="images/bachchan-amitabh-image.jpg" />
                      </a>
                    </div>
                    <div className="msg">
                      <p>
                        {contactName} invited you to NYE Party on <i className="bi bi-calendar3 " />{" "}
                        December 31, 2024.
                      </p>
                    </div>
                  </div>
                  <span className="msg-by-time">
                    Feb 1, 2023{" "}
                    <span className="chat-msg-time-detail">
                      (Wednesday) - <i className="bi bi-three-dots " />{" "}
                      <a href="contact_detail.html">{contactName}</a> at <time>11:15 am</time>
                    </span>
                  </span>
                </li>*/}
                <li className="other">&nbsp;</li>
              </ul>
            </div>
            <div
              id="mCSB_1_scrollbar_vertical"
              className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical"
              style={{ display: "none" }}
            >
              <div className="mCSB_draggerContainer">
                <div
                  id="mCSB_1_dragger_vertical"
                  className="mCSB_dragger"
                  style={{ position: "absolute", minHeight: "30px", height: "0px", top: "0px" }}
                  onContextMenu={() => {
                    return false
                  }}
                >
                  <div className="mCSB_dragger_bar" style={{ lineHeight: "30px" }} />
                  <div className="mCSB_draggerRail" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ChatDockFooterContainer">
          {historyType === "private_note" && (
            <div className="ChatDockOpt ChatDockPrivateNoteOpt">
              <div className="mb-2">
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add Note"
                    value={notesTextArea}
                    onChange={event => {
                      setNotesTextArea(event.target.value)
                    }}
                    onMouseLeave={event => {
                      setNotesTextArea(event.target.value)
                    }}
                  />
                  {notesTextAreaError && <div className="error-text">{notesTextAreaError}</div>}
                </Form>
              </div>
              <div className="row">
                <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                  <a
                    data-bs-toggle="modal"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add URL"
                    aria-label="Add URL"
                  >
                    <i className="bi bi-link-45deg" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Insert Image/Video"
                    aria-label="Insert Image/Video"
                  >
                    <i className="bi bi-camera-fill" />
                  </a>
                  <a
                    className="chatNoteImg"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Upload file"
                    aria-label="Upload file"
                  >
                    <i className="bi bi-paperclip" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Inject Emoji"
                    aria-label="Inject Emoji"
                  >
                    <i className="bi bi-emoji-smile" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </div>
                <div className="col-lg-6 text-end">
                  <button type="button" className="btn btn-primary" onClick={addNotes}>
                    <i className="bi bi-check2" />
                    Add Note
                  </button>
                </div>
              </div>
            </div>
          )}

          {historyType === "sequence" && (
            <div className="ChatDockOpt ChatDockSequenceOpt">
              {contactSequenceData.length > 0 && (
                <div className="ChatDockUserSequenceList">
                  <ul>
                    {contactSequenceData.map((historyValue, historyIndex) => {
                      return (
                        <li key={historyIndex}>
                          <div className="iconTextBoxContainer contactBox mt-1">
                            <div className="iconTextBox">
                              <span className="iconTextBoxIcon">
                                <img src={profilePic} />
                              </span>
                              <a href="#" className="iconTextBoxText">
                                {" "}
                                {contactName}{" "}
                              </a>
                            </div>
                          </div>{" "}
                          was added to{" "}
                          <div className="iconTextBoxContainer pinkBox mt-1">
                            <div className="iconTextBox">
                              <span className="iconTextBoxIcon">
                                <i className="bi bi-three-dots" />
                              </span>
                              <Link
                                to={`/drive/sequence/${historyValue.file_url}`}
                                className="iconTextBoxText"
                              >
                                {historyValue.name}
                              </Link>
                            </div>
                          </div>{" "}
                          on {moment(historyValue.created_at).format(DATE_FORMAT)}{" "}
                          <OverlayTrigger overlay={<Tooltip>Delete {historyValue.name}?</Tooltip>}>
                            <Link
                              to={"#"}
                              className="btnChatDockRemoveFromSequence"
                              onClick={() => {
                                unassignContactSequence(historyValue.sequence_contact_id, historyValue.name)
                              }}
                            >
                              <i className="bi bi-x-lg" />
                            </Link>
                          </OverlayTrigger>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}

              <div className="row row-cols-sm-auto g-2 mt-2 align-items-center justify-content-center">
                <div className={`col-12 ${defaultSequence ? "col-sm-4" : ""}`}>
                  <div className="dropdown chatDockAddSequenceContainer">
                    <button
                      className="btn btnAddNewGreenDashed dropdown-toggle btnChatDockAddSequence"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <OverlayTrigger placement="top" overlay={<Tooltip>List of Sequences</Tooltip>}>
                        <i className="bi bi-plus-lg" />
                      </OverlayTrigger>
                    </button>
                    <ul className="dropdown-menu" style={{}}>
                      {sequenceData.length > 0 &&
                        sequenceData.map(sequenceValue => {
                          return (
                            <li key={sequenceValue.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                data-value={sequenceValue.id}
                                onClick={e => {
                                  setDefaultSequence(sequenceValue.id)
                                  setSequenceType(sequenceValue.metaJson?.automationLogic)
                                  addSequence(sequenceValue.id, sequenceValue.metaJson?.automationLogic)
                                }}
                              >
                                {" "}
                                {sequenceValue.name}{" "}
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
                {defaultSequence && (
                  <>
                    <div className="col-12 col-sm-4 ChatDockSequenceDateTimeContainer">
                      {sequenceType === "BeforeAfter" && (
                        <div className="dropdown">
                          <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {selectedEventTitle}
                          </button>

                          <ul className="dropdown-menu" style={{}}>
                            {contactAllEvent.length > 0 &&
                              contactAllEvent.map(eventValue => {
                                return (
                                  <li key={eventValue.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      data-value={eventValue.id}
                                      onClick={e => {
                                        setSelectedEventId(eventValue.id)
                                        setSelectedEventTitle(eventValue.title)
                                      }}
                                    >
                                      {" "}
                                      {eventValue.title}{" "}
                                    </a>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {defaultSequence && (
                  <div className="col-12 col-sm-4 ChatDockSequenceSaveBtnContainer">
                    <button
                      className="btn btn-primary btbChatDockSaveSequence"
                      onClick={() => {
                        addSequence(defaultSequence, sequenceType)
                      }}
                    >
                      <i className="bi bi-check2" /> Save/Add
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {historyType === "chatTo" && (
            <div className="ChatDockOpt ChatDockChatToOpt">
              <div className="mb-2 ChatDockEmailToOpt w-100">
                <div className="row align-items-center">
                  <div className="col-sm-12">
                    <div className="d-inline-block dropup">
                      <button
                        className="btn btnAddNewGreenDashed"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span data-bs-toggle="tooltip" title="Aimo">
                          <i className="bi bi-robot " />
                        </span>
                      </button>
                      <ul className="dropdown-menu scrollable-menu">
                        <li>
                          <strong className="dropdown-item-text">== Email ==</strong>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            AIDA - Attn, Interest, Desire, Action
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Pain, Agitate, Solve
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Cold Email
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Email Newsletter
                          </a>
                        </li>
                        <li>
                          <strong className="dropdown-item-text">== ARTICLES & BLOG ==</strong>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Article Writer Collab Style
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Article Writer Title Dependent
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Article Rewriter (English)
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Article Outliner
                          </a>
                        </li>
                        <li>
                          <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Twitter Tweets
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            LinkedIn Posts
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            Instagram Captions
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            YouTube Helper
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                            TikTok Helper
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="d-inline-block">
                      <button
                        className="btn btnAddNewGreenDashed"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span data-bs-toggle="tooltip" title="Templates">
                          <i className="bi bi-journals " />
                        </span>
                      </button>
                      <ul className="dropdown-menu scrollable-menu">
                        <li>
                          <a className="dropdown-item" href="javascript:;">
                            Doc 1
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="javascript:;">
                            Doc 2
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="d-inline-block">
                      <button
                        className="btn btnAddNewGreenDashed"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span data-bs-toggle="tooltip" title="Snippets">
                          <i className="bi bi-pencil-square " />
                        </span>
                      </button>
                      <ul className="dropdown-menu scrollable-menu">
                        <li>
                          <a className="dropdown-item" href="javascript:;">
                            Snippets 1
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="javascript:;">
                            Snippets 2
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <textarea className="form-control ChatDockEmailBox" placeholder="Message" rows="5"></textarea>
              </div>
              <div className="mb-2 text-end">
                <div className="row">
                  <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                    <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill " />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                            <i className="bi bi-play-fill " /> Select Existing / Template
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnChatDockVideoRecording" href="javascript:;">
                            <i className="bi bi-record-fill " /> Record New
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:;">
                            <i className="bi bi-cloud-arrow-up-fill " /> Upload
                          </a>
                        </li>
                      </ul>
                    </div>
                    <a href="#chatDockURLInjector" data-bs-toggle="modal">
                      <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg " />
                    </a>
                    <a href="#Global_Uploader" data-bs-toggle="modal">
                      <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill " />
                    </a>
                    <a className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                      <i className="bi bi-paperclip " />
                    </a>
                    <a id="ChatDockChatToEmoji" data-bs-toggle="tooltip" title="Inject Emoji">
                      <i className="bi bi-emoji-smile " />
                    </a>
                    <input
                      type="file"
                      accept="image/*"
                      className="chatNoteImgFile"
                      style={{ visibility: "hidden", position: "absolute" }}
                    />
                  </div>
                  <div className="col-sm-4 text-end">
                    <button type="button" className="btn btn-primary btnChatDockSendChatMsg">
                      <i className="bi bi-send-fill " /> Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {historyType === "email" && (
            <SendEMail
              contactId={contact.id}
              emails={userEMails}
              historyValue={historyValue}
              setUpdateHistoryBlock={setUpdateHistoryBlock}
            />
          )}

          {historyType === "SMS" && (
            <div className="ChatDockOpt ChatDockSMSOpt">
              <div className="mb-2">
                <textarea
                  className="form-control"
                  id="smsMessagebox"
                  placeholder="Message"
                  rows="3"
                ></textarea>
              </div>
              <div className="row">
                <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
                  <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill " />
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                          <i className="bi bi-play-fill " /> Select Existing / Template
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoRecording" href="javascript:;">
                          <i className="bi bi-record-fill " /> Record New
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:;">
                          <i className="bi bi-cloud-arrow-up-fill " /> Upload
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a href="#chatDockURLInjector" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg " />
                  </a>
                  <a href="#Global_Uploader" data-bs-toggle="modal">
                    <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill " />
                  </a>
                  <a className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
                    <i className="bi bi-paperclip " />
                  </a>
                  <a id="ChatDockSMSEmoji" data-bs-toggle="tooltip" title="Inject Emoji">
                    <i className="bi bi-emoji-smile " />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                  <span className="ChatDockSMSCounter">140 remaining</span>
                </div>
                <div className="col-sm-4 text-end">
                  <button type="button" className="btn btn-primary btnChatDockSendSMS">
                    <i className="bi bi-send-fill " /> Send
                  </button>
                </div>
              </div>
            </div>
          )}
          {historyType === "phone" && (
            <div className="ChatDockOpt ChatDockPhoneOpt">
              <div className="phoneCallingContainer">
                {callNow && (
                  <div className="row align-items-center mb-2 phone-started">
                    <div className="col-sm-6">
                      <button
                        type="button"
                        className="btn btn-danger btn-lg btnChatDockCallEnd"
                        onClick={() => setCallNow(false)}
                      >
                        <i className="bi bi-x-lg " /> Hang Up
                      </button>
                    </div>
                    <div className="col-sm-6 text-end">
                      <div className="d-inline-block">
                        <strong className="timerStart fs-4">00:00:00</strong>
                      </div>

                      <div className="text-end d-inline-block phone-started ms-2 d-none">
                        <span
                          className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallRecord"
                          data-bs-toggle="tooltip"
                          data-bs-title="Record"
                        >
                          <input type="checkbox" id="ChatDockPhoneCallRecord" />
                          <label htmlFor="ChatDockPhoneCallRecord" className="checkmark" />
                        </span>
                        <span
                          className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallMute"
                          data-bs-toggle="tooltip"
                          data-bs-title="Mute"
                        >
                          <input type="checkbox" id="ChatDockPhoneCallMute" />
                          <label htmlFor="ChatDockPhoneCallMute" className="checkmark" />
                        </span>
                        <span
                          className="btn btn-default chatDockPhoneCallBtns ChatDockPhoneCallHold"
                          data-bs-toggle="tooltip"
                          data-bs-title="Hold"
                        >
                          <input type="checkbox" id="ChatDockPhoneCallHold" />
                          <label htmlFor="ChatDockPhoneCallHold" className="checkmark" />
                        </span>
                        <button type="button" className="btn btnChatDockPhoneCallAudioSettings">
                          <i
                            className="bi bi-gear-fill"
                            data-bs-toggle="tooltip"
                            data-bs-title="Audio Settings "
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row align-items-center">
                  <div className="col-sm-5">
                    <div className="mb-2">
                      <textarea className="form-control" rows="6" placeholder="Optional Call Notes" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="text-start ChatDockPrivateNoteTools">
                        <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              data-bs-toggle="tooltip"
                              title="Video Mail"
                              className="bi bi-camera-video-fill "
                            />
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                                <i className="bi bi-play-fill " /> Select Existing / Template
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item btnOpenChatDockVideoCall" href="javascript:;">
                                <i className="bi bi-record-fill " /> Record New
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item btnChatDockVideoMailUpload" href="javascript:;">
                                <i className="bi bi-cloud-arrow-up-fill " /> Upload
                              </a>
                            </li>
                          </ul>
                        </div>
                        <a href="#chatDockURLInjector" data-bs-toggle="modal">
                          <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg " />
                        </a>
                        <a href="#Global_Uploader" data-bs-toggle="modal">
                          <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill " />
                        </a>
                        <a className="chatNoteImg" data-bs-toggle="tooltip" data-bs-title="Upload file">
                          <i className="bi bi-paperclip " />
                        </a>
                        <a href="#" data-bs-toggle="tooltip" data-bs-title="Inject Emoji">
                          <i className="bi bi-emoji-smile " />
                        </a>
                        <input
                          type="file"
                          accept="image/*"
                          className="chatNoteImgFile"
                          style={{ visibility: "hidden", position: "absolute" }}
                        />
                      </div>
                      <div className="text-end">
                        <button type="button" className="btn btn-primary btnChatDockSendPrivateNote">
                          <i className="bi bi-check2 " /> Add Note
                        </button>
                      </div>
                    </div>
                  </div>
                  {!callNow && (
                    <div className="col-sm-7 text-center phone-ended">
                      <button
                        className="btn btn-primary btnChatDockCallNow mt-2 mt-sm-0"
                        onClick={() => setCallNow(true)}
                      >
                        <i className="bi bi-telephone-fill " /> Call Now
                      </button>
                    </div>
                  )}
                  {callNow && (
                    <div className="col-sm-7 text-center phone-started">
                      <div className="row g-0 align-items-center">
                        <div className="col-sm-6 text-center phone-started pb-3 chatDockTransferMergeBox">
                          <div className="ChatDockPhoneCallHoldOptContainer phone-started d-none">
                            {/*CALL NOW - TRANSFER CALL*/}
                            <div className="ChatDockPhoneCallTransferCallContainer text-start mt-2 ">
                              <p className="fw-bold mb-1 position-relative">
                                <i className="bi bi-reply-fill flip-horizontal " /> Transfer Call
                                <a className="btnChatDockCancelTransferCall">
                                  <i className="bi bi-x-lg " />
                                </a>
                              </p>

                              <div className="mb-1">
                                <input
                                  type="radio"
                                  name="chatDockForwardCallTo"
                                  id="chatDockForwardCallToContact"
                                  value="Contact"
                                  checked="checked"
                                  className="css-checkbox"
                                />
                                <label
                                  htmlFor="chatDockForwardCallToContact"
                                  className="css-radio-label me-2"
                                >
                                  Contact
                                </label>
                                <input
                                  type="radio"
                                  name="chatDockForwardCallTo"
                                  id="chatDockForwardCallToPhone"
                                  value="Phone"
                                  className="css-checkbox"
                                />
                                <label htmlFor="chatDockForwardCallToPhone" className="css-radio-label me-2">
                                  Phone
                                </label>
                              </div>
                              <div className="ChatDockPhoneCallTransferToInputContainer mb-2">
                                <input
                                  type="text"
                                  className="form-control chatDockForwardCallToContactInput"
                                  placeholder="Name or Phone Number"
                                />
                                <input
                                  type="tel"
                                  className="form-control chatDockForwardCallToPhoneInput d-none"
                                  placeholder=""
                                />
                              </div>
                              <div className="ChatDockPhoneCallTransferToContainer mb-2 d-none">
                                Also on call
                                <ul className="user-card-list">
                                  <li>
                                    <a className="btnChatDockRemovePhoneCallMerge">
                                      <i className="bi bi-x-lg " />
                                    </a>
                                    <img src="images/Rosa.jpg" alt="" className="user_avatar" />
                                    Rosa
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 btnChatDockTransferCallDialNo"
                                >
                                  <i className="bi bi-telephone-fill " /> Dial 3rd Number
                                </button>
                                {/*SHOW AFTER PHONE PICKED UP / CALL IS LIVE*/}
                                <button
                                  type="button"
                                  className="btn btn-danger w-100 d-none mb-2 btnChatDockTransferCallHangUp"
                                >
                                  <i className="bi bi-x-lg " /> Hang Up
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 d-none btnChatDockTransferCall"
                                >
                                  <i className="bi bi-reply-fill flip-horizontal " /> Transfer
                                </button>
                              </div>
                            </div>

                            {/*CALL NOW - MERGE CALL*/}
                            <div className="ChatDockPhoneCallMergeCallContainer text-start mt-2 d-none">
                              <p className="fw-bold mb-1 position-relative">
                                <i className="bi bi-share-fill flip-horizontal " /> Add to Call (3-Way)
                                <a className="btnChatDockCancelMergeCall">
                                  <i className="bi bi-x-lg " />
                                </a>
                              </p>

                              <div className="mb-1">
                                <input
                                  type="radio"
                                  name="chatDockMergeCallTo"
                                  id="chatDockMergeCallToContact"
                                  value="Contact"
                                  checked="checked"
                                  className="css-checkbox"
                                />
                                <label htmlFor="chatDockMergeCallToContact" className="css-radio-label me-2">
                                  Contact
                                </label>
                                <input
                                  type="radio"
                                  name="chatDockMergeCallTo"
                                  id="chatDockMergeCallToPhone"
                                  value="Phone"
                                  className="css-checkbox"
                                />
                                <label htmlFor="chatDockMergeCallToPhone" className="css-radio-label me-2">
                                  Phone
                                </label>
                              </div>
                              <div className="ChatDockPhoneCallMergeWithInputContainer mb-2">
                                <input
                                  type="text"
                                  className="form-control chatDockMergeCallToContactInput"
                                  placeholder="Name or Phone Number"
                                />
                                <input
                                  type="tel"
                                  className="form-control chatDockMergeCallToPhoneInput d-none"
                                  placeholder=""
                                />
                              </div>
                              <div className="ChatDockPhoneCallMergeWithContainer mb-2 d-none">
                                Also on call
                                <ul className="user-card-list">
                                  <li>
                                    <a className="btnChatDockRemovePhoneCallMerge">
                                      <i className="bi bi-x-lg " />
                                    </a>
                                    <img src="images/Rosa.jpg" alt="" className="user_avatar" />
                                    Rosa
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 btnChatDockMergeCallDialNo"
                                >
                                  <i className="bi bi-telephone-fill " /> Dial 3rd Number
                                </button>
                                {/*SHOW AFTER PHONE PICKED UP / CALL IS LIVE*/}
                                <button
                                  type="button"
                                  className="btn btn-danger w-100 d-none mb-2 btnChatDockMergeCallHangUp"
                                >
                                  <i className="bi bi-x-lg " /> Hang Up
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 d-none btnChatDockMergeCall"
                                >
                                  <i className="bi bi-share-fill flip-horizontal " /> Merge
                                </button>
                              </div>
                            </div>

                            <div className="ChatDockPhoneCallHoldOpt mt-3 d-none">
                              <input
                                type="radio"
                                name="ChatDockPhoneCallHoldOpt"
                                id="ChatDockPhoneCallTransfer"
                                value="Transfer"
                              />
                              <label htmlFor="ChatDockPhoneCallTransfer" className="checkmark">
                                Transfer
                              </label>
                              <input
                                type="radio"
                                name="ChatDockPhoneCallHoldOpt"
                                id="ChatDockPhoneCallMerge"
                                value="Merge"
                              />
                              <label htmlFor="ChatDockPhoneCallMerge" className="checkmark">
                                Merge
                              </label>
                            </div>
                          </div>
                        </div>
                        {callNow && (
                          <div className="col-sm-6 text-center phone-started py-3 ">
                            <div className="keypad_container">
                              <button className="btn btn-default keypad__key" value="1">
                                1
                              </button>
                              <button className="btn btn-default keypad__key" value="2">
                                2
                              </button>
                              <button className="btn btn-default keypad__key" value="3">
                                3
                              </button>
                              <button className="btn btn-default keypad__key" value="4">
                                4
                              </button>
                              <button className="btn btn-default keypad__key" value="5">
                                5
                              </button>
                              <button className="btn btn-default keypad__key" value="6">
                                6
                              </button>
                              <button className="btn btn-default keypad__key" value="7">
                                7
                              </button>
                              <button className="btn btn-default keypad__key" value="8">
                                8
                              </button>
                              <button className="btn btn-default keypad__key" value="9">
                                9
                              </button>
                              <button className="btn btn-default keypad__key" value="*">
                                *
                              </button>
                              <button className="btn btn-default keypad__key" value="0">
                                0
                              </button>
                              <button className="btn btn-default keypad__key" value="#">
                                #
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {historyType === "videoRecording" && (
            <div className="ChatDockOpt ChatDockVideoRecordingOpt">
              <div className="row align-items-center mb-2 ChatDockVideoRecordingActionContainer">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg btnChatDockVideoRecordingEnd btnChatDockVideoRecordingAfterButton d-none"
                    >
                      <i className="bi bi-x-lg " /> Stop Recording
                    </button>

                    <div className="text-end">
                      <div className="d-inline-block btnChatDockVideoRecordingAfterButton d-none">
                        <strong className="videoRecordingTimer fs-4">00:00:00</strong>
                      </div>

                      <div className="text-end d-inline-block ms-2">
                        <button type="button" className="btn btnChatDockVideoAudioSettings">
                          <i
                            className="bi bi-gear-fill"
                            data-bs-toggle="tooltip"
                            data-bs-title="Video / Audio Settings "
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ratio ratio-16x9">
                <div className="">
                  <div className="chatDockStartVideoRecordingContainer">
                    <button type="button" className="btn btn-primary btn-lg btnChatDockStartVideoRecording">
                      <i className="bi bi-play-circle " /> Start Recording
                    </button>
                  </div>
                  <div className="ChatDockVideoRecordProcessing d-none text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div className="ChatDockVideoRecordingWebCamContainer d-none text-center">
                    Webcam goes here...
                  </div>
                  <div className="ChatDockVideoRecordingSaveContainer d-none text-center">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group input-group-lg mb-3">
                          <span className="input-group-text">Save as</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={`${contactName}Video Message ${Date}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <button className="btn btn-default btn-lg btnChatDockVideoRecordingDiscard">
                        <i className="bi bi-x-lg " /> Discard
                      </button>
                      // SAVE TO FILES + LOG TO HISTORY ABOVE
                      <button className="btn btn-primary btn-lg">
                        <i className="bi bi-check2 " /> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {historyType === "videocall" && (
            <div className="ChatDockOpt ChatDockVideocallOpt">
              <div className="row align-items-center mb-2 ChatDockVideocallActionContainer">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg btnChatDockVideoCallEnd btnChatDockVideoCallAfterButton d-none"
                    >
                      <i className="bi bi-x-lg " /> End Meeting / Hang Up
                    </button>

                    <div className="text-end">
                      <div className="d-inline-block btnChatDockVideoCallAfterButton d-none">
                        <strong className="videoCallTimer fs-4">00:00:00</strong>
                      </div>

                      <div className="text-end d-inline-block ms-2">
                        <span
                          className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallRecord btnChatDockVideoCallAfterButton d-none"
                          data-bs-toggle="tooltip"
                          data-bs-title="Record"
                        >
                          <input type="checkbox" id="ChatDockVideoCallRecord" />
                          <label htmlFor="ChatDockVideoCallRecord" className="checkmark"></label>
                        </span>
                        <span
                          className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallMute btnChatDockVideoCallAfterButton d-none"
                          data-bs-toggle="tooltip"
                          data-bs-title="Mute"
                        >
                          <input type="checkbox" id="ChatDockVideoCallMute" />
                          <label htmlFor="ChatDockVideoCallMute" className="checkmark"></label>
                        </span>
                        <span
                          className="btn btn-default chatDockVideoCallBtns ChatDockVideoCallHold btnChatDockVideoCallAfterButton d-none"
                          data-bs-toggle="tooltip"
                          data-bs-title="Hold"
                        >
                          <input type="checkbox" id="ChatDockVideoCallHold" />
                          <label htmlFor="ChatDockVideoCallHold" className="checkmark"></label>
                        </span>
                        <button type="button" className="btn btnChatDockVideoAudioSettings">
                          <i
                            className="bi bi-gear-fill"
                            data-bs-toggle="tooltip"
                            data-bs-title="Video / Audio Settings "
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ratio ratio-16x9">
                <div className="">
                  <div className="chatDockStartVideoCallContainer">
                    <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit my-3 ">
                      <button type="button" className="btn btn-primary btn-lg btnChatDockVideoCall">
                        <i className="bi bi-play-circle " /> Start New Video Meeting
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-lg dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item btnVideoChatOptMeetNow" href="javascript:;">
                            <i className="bi bi-envelope-fill " /> Meet Now &amp; Email Meeting URL
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnVideoChatOptMeetNow" href="javascript:;">
                            <i className="bi bi-phone " /> Meet Now &amp; SMS Meeting URL
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnVideoChatOptCreateMeeting" href="javascript:;">
                            <i className="bi bi-link-45deg " /> Create Meeting Link for Future
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item btnVideoChatOptScheduleMeeting" href="javascript:;">
                            <i className="bi bi-calendar3 " /> Schedule a Meeting
                          </a>
                        </li>
                      </ul>
                      AddSequence
                    </div>

                    <button
                      className="btn btn-default btn-lg ms-2 btnChatDockVideoRecording"
                      data-bs-toggle="tooltip"
                      title="Record Video Clip"
                    >
                      <i className="bi bi-record-fill " />
                    </button>
                  </div>
                  <div className="ChatDockVideocallProcessing d-none text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div className="ChatDockVideocallWebCamContainer d-none text-center">
                    {/*<video id="videoCam" playsinline autoplay muted></video>*/}
                    {/*<div style="color: #ff6565" id="videoCamErrorMsg"></div>*/}
                    <p className="fs-5 fw-bold">Awaiting your guest.</p>
                    <p>
                      <a href="https://SwiftMeeting.com/xyz123" target="_blank">
                        SwiftMeeting.com/xyz123
                      </a>
                    </p>
                    <div>
                      <div className="btn-group btnAddNewDropdown btnAddNewDropdownSplit my-3 ">
                        <button type="button" className="btn btn-primary">
                          <i className="bi bi-envelope-fill " /> Email Meeting Link
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              data-type="Email"
                              data-value="amitabh@domain.com"
                              href="javascript:;"
                            >
                              <i className="bi bi-envelope-fill " /> Email to amitabh@domain.com
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              data-type="Email"
                              data-value="amitabh2@domain.com"
                              href="javascript:;"
                            >
                              <i className="bi bi-envelope-fill " /> Email to amitabh2@domain.com
                            </a>
                          </li>
                        </ul>
                      </div>
                      <button className="btn btn-primary">
                        <i className="bi bi-phone " /> SMS Meeting Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {historyType === "map" && (
            <div className="ChatDockOpt ChatDockDirectionsOpt">
              <div className="text-center py-3">
                <div className="btn-group dropup">
                  <button
                    type="button"
                    className="btn btn-primary btnChatDockGetDirection"
                    onClick={() => {
                      if (historyValue) {
                        let mapAddress = historyValue
                        mapAddress = mapAddress.replace(",", "/")
                        window.open("https://www.google.com/maps/dir/" + mapAddress, "_blank")
                      }
                    }}
                  >
                    Get Directions
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        From OtherAddressesFromSettingsGeneral
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {historyType === "postalMail" && (
            <div className="ChatDockOpt ChatDockPostalMailOpt">
              <div className="py-3">
                <div className="row row-cols-lg-auto g-2 align-items-center justify-content-center">
                  <div className="col-12 col-sm-4">
                    <select className="form-select ChatDockPostalMailType">
                      <option value="Shipping Label">Shipping Label</option>
                      <option value="Mail-Merge Doc">Mail-Merge Doc</option>
                      <option value="PDF">PDF</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4 ChatDockPostalMailTypeDropdown d-none">
                    <select className="form-select">
                      <option value="">== DOC ==</option>
                      <option value="Doc 1">Doc 1</option>
                    </select>
                  </div>
                  <div className="col-12  col-sm-4 ChatDockPostalMailPrintBtnContainer">
                    <button className="btn btn-primary btbChatDockPostalMailPrintSend">
                      <i className="bi bi-printer-fill " /> Print <i className="bi bi-send-fill " />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {generateReport && (
            <div className="ChatDockOpt ChatDockReportOpt">
              <div className="text-center py-3">
                <button type="button" className="btn btn-primary">
                  <i className="bi bi-bar-chart-line-fill" />
                  Generate Report
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default History
