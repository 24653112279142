import * as React from "react"
import { useEffect, useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import AxiosInstance from "../../../../services/axiosInstance"
import { navigate } from "gatsby"

const SendEMail = ({ contactId, emails, historyValue, setUpdateHistoryBlock }) => {
  const [emailSubject, setEmailSubject] = useState(""),
    [emailBody, setEmailBody] = useState(""),
    [selectedEmail, setSelectedEmail] = useState(emails[0]),
    [updateHistory, setUpdateHistory] = useState(false)

  const sendEMailToContact = () => {
    if (emailSubject === undefined || emailSubject === "") {
      alert("Please add email Subject")
      return false
    }

    if (emailBody === undefined || emailBody === "") {
      alert("Please add email Body")
      return false
    }

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/email/send/${contactId}`, {
        recipients: [historyValue],
        subject: emailSubject,
        content: emailBody,
      })
        .then(function (response) {
          if (response.status === 200) {
            setEmailSubject("")
            setEmailBody("")
            setUpdateHistory(true)
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (window.confirm(error.response.data)) {
              window.open("/settings/communication", "_blank")
            }
          } else {
          }
        })
    })
  }

  useEffect(() => {
    if (updateHistory) {
      setUpdateHistory(false)
      setUpdateHistoryBlock(true)
    }
  })

  return (
    <div className="ChatDockOpt ChatDockEmailOpt">
      <div className="mb-2 ChatDockEmailToOpt w-100">
        <div className="row align-items-center">
          <div className="col-sm-4">
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip" title="Aimo">
                  <i className="bi bi-robot " />
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu">
                <li>
                  <strong className="dropdown-item-text">== Email ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    AIDA - Attn, Interest, Desire, Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Pain, Agitate, Solve
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Cold Email
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Email Newsletter
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== ARTICLES & BLOG ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Collab Style
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Title Dependent
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Rewriter (English)
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Outliner
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Twitter Tweets
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    LinkedIn Posts
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Instagram Captions
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    YouTube Helper
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    TikTok Helper
                  </a>
                </li>
              </ul>
            </div>{" "}
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip" title="Templates">
                  <i className="bi bi-journals " />
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockEmailDocs">
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 2
                  </a>
                </li>
              </ul>
            </div>{" "}
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip" title="Snippets">
                  <i className="bi bi-pencil-square " />
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockEmailSnippets">
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 2
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="chatDockEmailViaCcBccControls">
              <div className="chatDockEmailViaControls">
                <div>via</div>&nbsp;
                {emails.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic" bsPrefix="form-select invisibleField">
                      {selectedEmail}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "250px", overflow: "auto" }}>
                      {emails.map((email, index) => {
                        return (
                          <>
                            {email && (
                              <Dropdown.Item
                                value={email}
                                key={index}
                                onClick={e => {
                                  if (email === "Connect Email Sender") {
                                    navigate("/settings/communication")
                                  }
                                  setSelectedEmail(email)
                                }}
                              >
                                {email}
                              </Dropdown.Item>
                            )}
                          </>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {emails.length <= 0 && <span>Please add contact Email First.</span>}
              </div>
              <div className="chatDockEmailCcBccControls">
                <div className="col-auto d-inline-block ChatDockEmailAs">
                  <div className="d-inline-block">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Default">
                        <i className="bi bi-envelope-fill " />
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-envelope-fill " /> Default
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-lock-fill " /> Secure Message (Login Required)
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-code-slash " /> Branded Newsletter
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-list " /> Plain Text
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>{" "}
                <div className="col-auto d-inline-block ChatDockEmailCcBcc">
                  <div className="d-inline-block">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span data-bs-toggle="tooltip" title="Add Cc/Bcc">
                        <i className="bi bi-plus-lg " />
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-envelope-fill " /> Add Cc
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-envelope-fill " /> Add Bcc
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto ChatDockEmailCcBccInput position-relative d-none">
                  <a className="btnRemoveChatDockEmailCcBccInput">
                    <i className="bi bi-x-lg " />
                  </a>
                  <input type="email" className="form-control" placeholder="Enter email address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Subject"
          value={emailSubject}
          onChange={e => {
            setEmailSubject(e.target.value)
          }}
        />
      </div>
      <div className="mb-2">
        <textarea
          className="form-control ChatDockEmailBox"
          placeholder="Message"
          rows="5"
          value={emailBody}
          onChange={e => {
            setEmailBody(e.target.value)
          }}
        />
      </div>
      <div className="mb-2 text-end">
        <div className="row">
          <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
            <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
              <button
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i data-bs-toggle="tooltip" title="Video Mail" className="bi bi-camera-video-fill " />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                    <i className="bi bi-play-fill " /> Select Existing / Template
                  </a>
                </li>
                <li>
                  <a className="dropdown-item btnChatDockVideoRecording" href="#">
                    <i className="bi bi-record-fill " /> Record New
                  </a>
                </li>
                <li>
                  <a className="dropdown-item btnChatDockVideoMailUpload" href="#">
                    <i className="bi bi-cloud-arrow-up-fill " /> Upload
                  </a>
                </li>
              </ul>
            </div>
            <a href="#chatDockURLInjector" data-bs-toggle="modal">
              <i data-bs-toggle="tooltip" title="Add URL" className="bi bi-link-45deg " />
            </a>
            <a href="#Global_Uploader" data-bs-toggle="modal">
              <i data-bs-toggle="tooltip" title="Insert Image" className="bi bi-camera-fill " />
            </a>
            <a className="chatNoteImg" data-bs-toggle="tooltip" title="Upload file">
              <i className="bi bi-paperclip " />
            </a>
            <a id="ChatDockEmailEmoji" data-bs-toggle="tooltip" title="Inject Emoji">
              <i className="bi bi-emoji-smile " />
            </a>
            <input
              type="file"
              accept="image/*"
              className="chatNoteImgFile"
              style={{ visibility: "hidden", position: "absolute" }}
            />
          </div>
          <div className="col-sm-4 text-end">
            <button
              type="button"
              className="btn btn-primary btnChatDockSendEmail"
              onClick={sendEMailToContact}
            >
              <i className="bi bi-send-fill " /> Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendEMail
